import styled from '@emotion/styled'
import { BREAKPOINTS, pxToRemUnit } from 'utils/styled'
import { colors } from 'utils/colors'

export const SAuthWrapper = styled.div`
  box-shadow: 0 0 40px 0 #00000026;
  min-width: 12.5rem;
  padding: 2.75rem;
  max-width: ${pxToRemUnit(518)};
  width: 100%;
  flex-grow: 1;
  background-color: ${colors.white};

  @media ${BREAKPOINTS.MD.max} {
    padding: 0;
    box-shadow: none;
    align-self: center;
  }
`
export const SFormWrapper = styled.div`
  margin-bottom: 1.5rem;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  @media ${BREAKPOINTS.MD.max} {
    margin-bottom: 0;
  }
`
