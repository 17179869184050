import { handleAuthResponse, IAuthResponseOptions } from 'api/goodtrust/auth.util'
import { postFacebook, postGoogle } from 'api/goodtrust/social'
import { postUserRegister } from 'api/goodtrust/user'
import { handleSignupSuccess } from 'components/auth/register/handleSignupSuccess'
import { useOfferModal } from 'components/modal/offerModal/useOfferModal'
import { handleAndToastError, toastSuccess } from 'components/Toast'
import { SimpleFlowDirectiveType } from 'logic/directive/types'
import { useRouter } from 'next/router'
import type { IFormPayload } from 'pages/signup'
import { UseFormReturn } from 'react-hook-form'
import { useCobrandedOffer } from 'utils/cobranding/useCobrandedOffer'
import { ShouldNeverHappenError } from 'utils/error'
import { matchMap, noop } from 'utils/general'
import { IFacebookCallback, IGoogleSuccessCallback } from 'utils/social'
import { useState } from 'react'
import { describePromoCodes } from 'logic/promo/describePromoCodes'

export type HandleSingupArgs =
  | {
      type: 'form'
    }
  | {
      type: 'facebook'
      accessToken: string
    }
  | {
      type: 'google'
      accessToken: string
    }

// IMPROVE [Test] contactUuid behavior
export function useSignupLogic(
  form: UseFormReturn<IFormPayload>,
  contactUuid?: string,
  next?: string,
  flowDirective?: SimpleFlowDirectiveType,
  aol?: boolean
) {
  const [promoError, setPromoError] = useState<string | undefined | null>(null)
  const offerModal = useOfferModal(aol)
  const router = useRouter()
  const cobranding = useCobrandedOffer().signup

  async function handleSignup(args: HandleSingupArgs) {
    try {
      const promocode = form.getValues().code
      const shouldOpenOfferModal = promocode != null && promocode.length > 0
      try {
        if (aol && shouldOpenOfferModal) {
          const promo = await describePromoCodes(form.getValues().code).getExtendedPromotion()
          setPromoError(promo.cannotClaimReason)
        }
      } catch (e) {
        if (e.message === 'Requested discount not found') {
          setPromoError('entity_not_found_promo_code_not_found')
        } else {
          throw e
        }
      }

      const autoAccept = cobranding.shouldAutoAcceptOffer(form.getValues().code) || aol
      const offerModalResponse = shouldOpenOfferModal
        ? await offerModal.open(form.getValues().code, { autoAccept })
        : undefined

      if (offerModalResponse?.type === 'cancel') return

      const authResponse = await matchMap(args.type, {
        form: async () =>
          postUserRegister({
            email: form.getValues().email,
            password: form.getValues().password,
            source: flowDirective ?? 'DEFAULT',
          }),
        google: async () =>
          postGoogle((args as Extract<HandleSingupArgs, { type: 'google' }>).accessToken),
        facebook: async () =>
          postFacebook((args as Extract<HandleSingupArgs, { type: 'facebook' }>).accessToken),
      })()

      const authOpts: IAuthResponseOptions = {
        contact: contactUuid,
        verifyEmail: true,
        onVerificationRequired: noop,
      }

      const authResult = await handleAuthResponse(
        authResponse,
        args.type === 'form' ? true : null,
        authOpts
      )

      // error message toasting should be already handled in handleAuthResponse
      if (!authResult) return

      if (authResult === 'login') {
        await router.push('/me/dashboard')
        return
      }

      if (authResult !== 'signup' && authResult !== 'verify') {
        // Auth result should be signup here.
        throw new ShouldNeverHappenError()
      }

      toastSuccess('your_account_created')

      await handleSignupSuccess({
        offerResult: offerModalResponse,
        next,
        cobranding,
        flowDirective,
        email: form.getValues('email'),
        verifyEmail: true,
      })
    } catch (err) {
      handleAndToastError(err)
    }
  }

  const onFacebook: IFacebookCallback = async ({ accessToken }) => {
    await handleSignup({ type: 'facebook', accessToken })
  }
  const onGoogle: IGoogleSuccessCallback = async ({ accessToken }) => {
    await handleSignup({ type: 'google', accessToken })
  }
  const onSubmit = form.handleSubmit(async () => {
    await handleSignup({ type: 'form' })
  })

  return {
    onFacebook,
    onGoogle,
    onSubmit,
    jsx: offerModal.jsx,
    promo: promoError,
  }
}
