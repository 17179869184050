import { RatingContainer, SList, SListItem } from './JoinGoodTrust.styled'
import { Icon } from 'components/icon'
import { ToneContext } from 'components/signup/Signup'
import { Stack } from 'components/Stack'
import { Caption, Text, Title, Title_h4_default } from 'components/Typography'
import React, { ReactNode, useContext } from 'react'
import { colors } from 'utils/colors'

export const JoinGoodTrust = ({
  title,
  caption,
  perks,
}: {
  title?: ReactNode
  caption?: string
  perks?: ReactNode[]
}) => {
  const tone = useContext(ToneContext)
  return (
    <Stack space={[24, 24]}>
      {caption && <Caption>{caption}</Caption>}
      <Title variant={Title_h4_default} as="h3">
        {title}
      </Title>
      <SList>
        {perks?.map((perk, i) => (
          <SListItem tone={tone} key={i}>
            <Icon
              name="CheckBold"
              size="1rem"
              color={tone === 'accent' ? colors.orange[200] : colors.green[200]}
            />
            <Text as={'p'}>{perk}</Text>
          </SListItem>
        ))}
      </SList>
      <RatingContainer />
    </Stack>
  )
}
