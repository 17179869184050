import { Register } from 'components/auth/register/Register'
import { useRouter } from 'next/router'
import { IFormPayload } from 'pages/signup'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { encodeQuery } from 'utils/fetcher'
import { Block } from '../Block'
import { BlockSpinner } from '../spinner/Spinner'
import { SignupSection } from './Signup.styled'

export const ToneContext = createContext<SignupTone>('')

export type SignupTone = 'accent' | 'standard' | 'dark' | 'success' | ''
export type SignupVariant = 'default' | 'group_life' | 'contact_us'

export function Signup(props: {
  caption?: string
  title: ReactNode
  buttonText?: string
  perks: ReactNode[]
  variant?: SignupVariant
}) {
  const variant = props.variant ?? 'default'
  const [isLoading, setIsLoading] = useState(false)
  const tone = useContext(ToneContext)

  const { isReady, query } = useRouter()
  const contactUuid = query.contact as string | undefined
  const next = query.next as string | undefined
  const { isLogged } = useAuth()
  const form = useForm<IFormPayload>({
    defaultValues: { is_promocode: false, code: '' },
  })

  useEffect(() => {
    if (isReady && !isLoading) {
      setIsLoading(false)
    }
  }, [isLoading, isReady])

  // we don't want to show Signup for users who are already logged in
  // because the main purpose of this component is to get new users
  // so it doesn't provide much benefit to existing users
  // this doesn't apply to group life, as filling the signup form is available
  // to logged in users as well, because they need to confirm their information
  if (isLogged && variant !== 'group_life' && variant !== 'contact_us') return null

  return (
    <SignupSection tone={tone}>
      {!isLoading ? (
        <Block>
          <Register
            form={form}
            loginHref={encodeQuery('/login', { contact: contactUuid, next })}
            tone={tone === '' ? undefined : tone}
            next={next}
            contactUuid={contactUuid}
            caption={props.caption}
            title={props.title}
            buttonText={props.buttonText}
            perks={props.perks}
            variant={variant}
          />
        </Block>
      ) : (
        <BlockSpinner />
      )}
    </SignupSection>
  )
}
