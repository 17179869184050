import { SAuthWrapper, SFormWrapper } from './AuthComponent.styled'
import { Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { Stack } from 'components/Stack'
import { Caption } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import { IFormPayload } from 'pages/signup'
import { UseFormReturn } from 'react-hook-form'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'

export function AuthComponent({
  oneClickTitle,
  authSocialElm,
  emailTitle,
  form,
  buttonGroupElm,
  isPromoCode,
}: {
  oneClickTitle: string
  authSocialElm: JSX.Element
  emailTitle: string
  form: UseFormReturn<IFormPayload>
  buttonGroupElm: JSX.Element
  isPromoCode?: boolean
}) {
  const { t: tCommon } = useTranslation()
  const validation = useFieldValidation()

  return (
    <SAuthWrapper>
      <Caption css={{ marginBottom: '1rem' }}>{oneClickTitle}</Caption>
      {authSocialElm}
      <SFormWrapper>
        <Caption>{emailTitle}</Caption>
        <FormFieldset form={form}>
          <Fieldset>
            <input
              {...form.register('email', {
                ...validation.required(),
                ...validation.email(),
              })}
              type="email"
              autoComplete="email"
              placeholder="&nbsp;"
            />
            <label>{tCommon('common.email_address')}</label>
          </Fieldset>
        </FormFieldset>
        <FormFieldset form={form}>
          <Fieldset>
            <input
              {...form.register('password', validation.required())}
              type="password"
              autoComplete="new-password"
              placeholder="&nbsp;"
            />
            <label>{tCommon('common.password')}</label>
          </Fieldset>
          {isPromoCode && (
            <FormFieldset form={form}>
              <Fieldset>
                <input {...form.register('code')} type="text" placeholder="&nbsp;" />
                <label>{tCommon('common.promotion_code')}</label>
              </Fieldset>
            </FormFieldset>
          )}
        </FormFieldset>
        <Stack space={24}>{buttonGroupElm}</Stack>
      </SFormWrapper>
    </SAuthWrapper>
  )
}
