import { defineApiResource } from 'api/goodtrust/api/define'
import { apiAuthFetcher } from 'api/goodtrust/api'

export const { post: postScheduleDemo } = defineApiResource('/api/v1/communication/schedule-demo', {
  requiresAuth: false,
})

export function postSendFamilyPlanCode() {
  return apiAuthFetcher('/api/v1/communication/send-family-code', { method: 'POST' })
}
