import styled from '@emotion/styled'
import { colors } from 'utils/colors'
import { BREAKPOINTS } from 'utils/styled'
import { SignupTone } from './Signup'

export const SignupSection = styled.div<{ tone?: SignupTone }>`
  background-color: ${(props) =>
    props.tone === 'standard'
      ? colors.blue[100]
      : props.tone === 'accent'
      ? colors.orange[100]
      : props.tone === 'dark'
      ? colors.gray[50]
      : props.tone === 'success'
      ? colors.green[100]
      : 'unset'};
  padding-top: ${(props) => (props.tone ? '5.75rem' : '')};
  @media ${BREAKPOINTS.MD.max} {
    background-color: ${colors.white};
    padding-top: 0;
  }
`
