import { css } from '@emotion/react'
import { Spacer } from 'components/spacer/Spacer'
import { Text } from 'components/Typography'
import ReviewStarsHalf from 'images/extra-svg/ReviewStarsHalf.svg'

export function ReviewStars(props: { reviewsCountLabel: string; className?: string }) {
  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        flex-direction: column;
        width: fit-content;
      `}
    >
      <ReviewStarsHalf />
      <Spacer size="0.5rem" />
      <Text variant={'bodyMedium'}>{props.reviewsCountLabel}</Text>
    </div>
  )
}
