import styled from '@emotion/styled'
import { Text } from 'components/Typography'

export const SLinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const STermsText = styled(Text)`
  text-align: center;
  align-self: center;
`
