import { SAuthContainer } from 'components/auth/AuthContainer'
import { JoinGoodTrust } from 'components/auth/joinGoodTrust/JoinGoodTrust'
import { MobileCaption, MobileTitle, SJoinGoodTrustStack, SWrapper } from './Register.styled'
import { SignupCard } from 'components/auth/signupCard/SignupCard'
import { ButtonTone } from 'components/button/types'
import { EnterpriseContactForm } from 'components/content/contact/contactForm/ContactForm'
import { GroupLifeSignupForm } from 'components/groupLifeSection/signup/Form'
import { Icon } from 'components/icon'
import { ReviewStars } from 'components/reviews/ReviewsStars'
import { SignupVariant } from 'components/signup/Signup'
import { HStack, Stack } from 'components/Stack'
import { Caption, Title_h4_default } from 'components/Typography'
import { useTranslation } from 'next-i18next'
import type { IFormPayload } from 'pages/signup'
import { ReactNode } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { colors } from 'utils/colors'
import { matchMap } from 'utils/general'
import { useStoredPromoCodeLogic } from 'utils/promo'

export function Register({
  loginHref,
  form,
  tone,
  contactUuid,
  next,
  caption,
  title,
  buttonText,
  perks,
  variant,
}: {
  variant: SignupVariant
  loginHref: string
  form: UseFormReturn<IFormPayload>
  tone?: ButtonTone
  next?: string
  contactUuid?: string
  caption?: string
  title?: ReactNode
  buttonText?: string
  perks?: ReactNode[]
}) {
  const { t } = useTranslation()
  const { t: tAuth } = useTranslation('auth')
  const isPromoCode = form.watch('is_promocode')

  useStoredPromoCodeLogic({
    onValidatedCode(code) {
      form.setValue('is_promocode', true)
      form.setValue('code', code)
    },
  })

  return (
    <FormProvider {...form}>
      <SAuthContainer>
        <SWrapper>
          <SJoinGoodTrustStack css={{ padding: '1.25rem 0' }}>
            <JoinGoodTrust title={title} caption={caption} perks={perks} />
            <ReviewStars reviewsCountLabel={t('common.signup.reviews_label')} />
          </SJoinGoodTrustStack>
          <Stack space={'1.5rem'} css={{ alignItems: 'center' }}>
            {caption && <MobileCaption>{caption}</MobileCaption>}
            <MobileTitle variant={Title_h4_default}>{title}</MobileTitle>
            {matchMap(variant, {
              default: (
                <>
                  <SignupCard
                    form={form}
                    isPromoCode={isPromoCode}
                    loginHref={loginHref}
                    buttonText={buttonText}
                    tone={tone}
                    contactUuid={contactUuid}
                    next={next}
                  />
                  <HStack css={{ alignSelf: 'center' }}>
                    <Icon name="LockClosed" color={colors.gray[500]} />
                    <Caption>{t('common.signup.security_promise')}</Caption>
                  </HStack>
                </>
              ),
              group_life: <GroupLifeSignupForm />,
              contact_us: (
                <>
                  <EnterpriseContactForm />
                  <HStack css={{ alignSelf: 'center' }}>
                    <Icon name="LockClosed" color={colors.gray[500]} />
                    <Caption>{tAuth('auth.login.encryption_text')}</Caption>
                  </HStack>
                </>
              ),
            })}
          </Stack>
        </SWrapper>
      </SAuthContainer>
    </FormProvider>
  )
}
