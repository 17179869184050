import styled from '@emotion/styled'
import { colors } from 'utils/colors'
import { SignupTone } from 'components/signup/Signup'

export const SList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`
export const SListItem = styled.li<{ tone?: SignupTone }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.625rem;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    margin-top: 0.3125rem;
    margin-right: 0.625rem;
    flex-shrink: 0;
    vertical-align: middle;
  }
  p {
    color: ${(props) => (props?.tone ? colors.black : colors.gray[500])};
  }
`

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
`
