import styled from '@emotion/styled'
import { Caption, Title } from 'components/Typography'
import { BREAKPOINTS, pxToRem, pxToRemUnit } from 'utils/styled'
import { Stack } from 'components/Stack'

export const MobileTitle = styled(Title)`
  display: none;
  @media ${BREAKPOINTS.MD.max} {
    display: block;
    max-width: ${pxToRemUnit(518)};
    align-self: flex-start;
  }
`

export const MobileCaption = styled(Caption)`
  display: none;
  @media ${BREAKPOINTS.MD.max} {
    display: block;
    align-self: flex-start;
  }
`

export const SJoinGoodTrustStack = styled(Stack)`
  padding: 1.25rem 0;
  @media ${BREAKPOINTS.MD.max} {
    padding: 0;
    display: none;
  }
`

export const SWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 ${pxToRem(130)}rem;
  margin-bottom: ${pxToRem(50)}rem;

  @media ${BREAKPOINTS.LG.max} {
    grid-gap: 2rem;
  }

  @media ${BREAKPOINTS.MD.max} {
    grid-template-columns: 1fr;
    grid-gap: 5rem;
  }
`
