import { Maybe } from '@graphql-tools/utils'
import { AuthComponent } from 'components/auth/authComponent/AuthComponent'
import { AuthSocial } from 'components/auth/authSocial/AuthSocial'
import { useSignupLogic } from 'components/auth/register/useSignupLogic'
import { SLinksWrapper, STermsText } from './SignupCard.styled'
import { Button } from 'components/button/Button'
import { ButtonTone } from 'components/button/types'
import { FormFieldset } from 'components/FormButton'
import { TermsAndPrivacyLinks } from 'components/TermsAndPrivacyLinks'
import { TranslatableLink } from 'components/TranslatableLink'
import { Text } from 'components/Typography'
import { Trans, useTranslation } from 'next-i18next'
import { IFormPayload } from 'pages/signup'
import { UseFormReturn } from 'react-hook-form'
import { useWindowSize } from 'utils/hooks'
import { onGoogleFailureToastError } from 'utils/social'

export const LinksWrapper = ({
  loginHref,
  form,
  isPromoCode,
  aol,
}: {
  loginHref: string
  form: UseFormReturn<IFormPayload>
  isPromoCode: boolean
  aol?: boolean
}) => {
  const { t } = useTranslation()
  return (
    <SLinksWrapper>
      <Text>
        <Trans
          t={t}
          i18nKey="common.signup.login_redirect_label"
          components={[<TranslatableLink key="0" href={loginHref} />]}
        />
      </Text>

      {!aol ? (
        <FormFieldset form={form}>
          <Button
            type="button"
            size="small"
            variant="text"
            onClick={() => {
              form.setValue('is_promocode', !isPromoCode)
            }}
          >
            <Text variant="body" css={{ fontWeight: 400, textDecoration: 'underline' }}>
              {t(isPromoCode ? 'common.signup.remove_promo' : 'common.signup.add_promo')}
            </Text>
          </Button>
        </FormFieldset>
      ) : null}
    </SLinksWrapper>
  )
}
export function SignupCard({
  form,
  isPromoCode,
  loginHref,
  buttonText,
  tone,
  contactUuid,
  next,
}: {
  form: UseFormReturn<IFormPayload>
  isPromoCode: boolean
  loginHref: string
  buttonText: Maybe<string>
  tone?: ButtonTone
  contactUuid?: string
  next?: string
}) {
  const { t } = useTranslation()
  const signupLogic = useSignupLogic(form, contactUuid, next)
  const { isDesktop } = useWindowSize()

  const signupAuthSocial = (
    <AuthSocial
      onFacebook={signupLogic.onFacebook}
      onGoogleSuccess={signupLogic.onGoogle}
      onGoogleFailure={(res) => onGoogleFailureToastError(res, 'failed_to_create_account')}
    />
  )

  const signupButtonGroupElms = (
    <>
      <LinksWrapper loginHref={loginHref} isPromoCode={isPromoCode} form={form} />
      <Button
        type="submit"
        loading={form.formState.isSubmitting}
        size="large"
        tone={isDesktop ? tone : undefined}
      >
        {buttonText || t('common.signup.default_button_label')}
      </Button>
      <STermsText variant="bodySmall">
        <Trans
          t={t}
          i18nKey={'common.signup.you_agree_notice'}
          components={[<TermsAndPrivacyLinks key="0" />]}
        />
      </STermsText>
    </>
  )

  return (
    <>
      <form
        onSubmit={signupLogic.onSubmit}
        css={{ width: '100%', display: 'flex', justifyContent: 'center' }}
      >
        {signupLogic.jsx}
        <AuthComponent
          oneClickTitle={t('common.signup.method_social_caption')}
          emailTitle={t('common.signup.method_email_caption')}
          authSocialElm={signupAuthSocial}
          form={form}
          buttonGroupElm={signupButtonGroupElms}
          isPromoCode={isPromoCode}
        />
      </form>
    </>
  )
}
