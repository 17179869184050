import { css } from '@emotion/react'
import { Fieldset } from 'components/fieldset/Fieldset'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import { Controller, useForm } from 'react-hook-form'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { handleAndToastError, toastSuccess } from 'components/Toast'
import { FormFieldset } from 'components/FormButton'
import { postScheduleDemo } from 'api/goodtrust/communication'
import { CustomPhoneInput } from 'components/inputs/phoneInput/PhoneInput'
import { Select } from 'components/select/Select'
import { Button } from 'components/button/Button'
import { Icon } from 'components/icon'
import { MessageException } from 'utils/error'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import { useTranslation } from 'next-i18next'
import { USE_SUPPORT_REASON_OPTIONS } from 'utils/constants'
import { Card } from 'components/card/Card'
import { Caption } from 'components/Typography'
import styled from '@emotion/styled'
import { Stack } from 'components/Stack'

interface IFormData {
  email: string
  phone_number: string
  full_name: string
  question_category: string
  message: string
}

type EnterpriseFormData = {
  email: string
  full_name: string
  message: string
}

const STextArea = styled.textarea`
  border: none;
  outline: none;
  font-family: var(--f-text);
  color: var(--c-gray100);
  font-size: 16px;
  line-height: 1.31;
  outline: none;
  cursor: text;
  min-height: ${pxToRem(100)}rem;
  width: 100%;
  resize: none;
  @media ${BREAKPOINTS.MD.max} {
    resize: none;
    height: auto;
  }
`

const SStack = styled(Stack)`
  min-width: 27.5rem;
  @media ${BREAKPOINTS.LG.max} {
    min-width: unset;
  }
`

export function EnterpriseContactForm() {
  const { t } = useTranslation('security')
  const validation = useFieldValidation()

  const defaultValues: EnterpriseFormData = {
    email: '',
    full_name: '',
    message: '',
  }
  const form = useForm<EnterpriseFormData>({
    defaultValues,
  })

  const handleSubmit = async (data: EnterpriseFormData) => {
    try {
      // GT-5331 says "make sure that question_category field is always set to Contact."
      const res = await postScheduleDemo({ ...data, question_category: 'Contact' })

      if (!res.ok) {
        throw new MessageException('failed_to_send_message')
      }

      toastSuccess('reach_out_soon')
      form.reset(defaultValues)
    } catch (err) {
      handleAndToastError(err, 'failed_to_send_form')
    }
  }

  return (
    <Card css={{ padding: '2.5rem' }}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <SStack space="0.75rem">
          <Caption>{t('security.enterprise.contact_us.form.caption')}</Caption>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('email', {
                  ...validation.required(),
                  ...validation.email(),
                })}
                type="text"
                placeholder="&nbsp;"
              />
              <label>{t('security.enterprise.contact_us.form.email_label')}</label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset>
              <input
                {...form.register('full_name', validation.required())}
                type="text"
                placeholder="&nbsp;"
              />
              <label>{t('security.enterprise.contact_us.form.name_label')}</label>
            </Fieldset>
          </FormFieldset>
          <FormFieldset form={form}>
            <Fieldset css={{ gridColumnEnd: 'span 2', flexGrow: 1, '& > *': { flexGrow: 1 } }}>
              <STextArea {...form.register('message')} placeholder="&nbsp;" />
              <label>{t('security.enterprise.contact_us.form.message_label')}</label>
            </Fieldset>
          </FormFieldset>
        </SStack>
        <Button
          type="submit"
          variant="primary"
          size="large"
          block
          tone="dark"
          css={{ marginTop: '1.5rem' }}
          loading={form.formState.isSubmitting}
        >
          {t('security.enterprise.contact_us.form.message_label')}
        </Button>
      </form>
    </Card>
  )
}

export function ContactForm(props: { className?: string }) {
  const { t } = useTranslation()
  const { t: tSec } = useTranslation('security')

  const validation = useFieldValidation()
  const form = useForm<IFormData>({
    defaultValues: {
      phone_number: '',
      question_category: '',
      message: 'Hello...',
    },
  })

  const handleSubmit = async (data: IFormData) => {
    try {
      const res = await postScheduleDemo(data)

      if (!res.ok) {
        throw new MessageException('failed_to_send_message')
      }

      toastSuccess('reach_out_soon')
      form.reset({
        phone_number: '',
        question_category: '',
      })
    } catch (err) {
      handleAndToastError(err, 'failed_to_send_form')
    }
  }
  const supportReasonOptions = USE_SUPPORT_REASON_OPTIONS()

  return (
    <form
      noValidate
      onSubmit={form.handleSubmit(handleSubmit)}
      className={props.className}
      css={css`
        display: flex;
        flex-direction: column;
        & > *:not(:last-child) {
          margin-bottom: 10px;
        }

        background: #fff;
        box-shadow: var(--card-shadow);

        padding: calc(var(--p-button) * 2);
        margin: calc(var(--p-button) * -2);
        margin-bottom: calc(var(--p-button) * -3 - 44px);

        @media ${BREAKPOINTS.MD.max} {
          padding: calc(var(--p-button) * 1.5);
          margin: 0;
        }
      `}
    >
      <FormFieldset form={form}>
        <Fieldset>
          <input
            {...form.register('email', validation.required())}
            type="email"
            placeholder="&nbsp;"
          />
          <label>{tSec('security.contact_page.form_email_label')}</label>
        </Fieldset>
      </FormFieldset>
      <Controller
        name="phone_number"
        rules={{
          validate: (value) => isPossiblePhoneNumber(value || '') || t('common.form.invalid_phone'),
        }}
        control={form.control}
        render={({ field }) => (
          <FormFieldset name="phone_number" form={form}>
            <CustomPhoneInput {...field} value={field.value || ''} />
          </FormFieldset>
        )}
      />
      <FormFieldset form={form}>
        <Fieldset>
          <input
            {...form.register('full_name', validation.required())}
            type="text"
            placeholder="&nbsp;"
          />
          <label>{tSec('security.contact_page.form_name_label')}</label>
        </Fieldset>
      </FormFieldset>

      <Controller
        name="question_category"
        rules={validation.required()}
        control={form.control}
        render={({ field }) => (
          <FormFieldset name={field.name} form={form}>
            <Select
              {...field}
              label={tSec('security.contact_page.form_category')}
              items={supportReasonOptions}
            />
          </FormFieldset>
        )}
      />

      <FormFieldset form={form}>
        <Fieldset>
          <textarea
            {...form.register('message', validation.required())}
            css={{ resize: 'vertical', minHeight: 160 }}
          />
          <label>{tSec('security.contact_page.form_message_label')}</label>
        </Fieldset>
      </FormFieldset>

      <Button type="submit" variant="text" size="large" leftIcon={<Icon name="Send" />}>
        {tSec('security.contact_page.form_submit_button')}
      </Button>
    </form>
  )
}
